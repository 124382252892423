import './bootstrap';

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});



$(document).ready(function() {
    $('.dropdown-item').click(function() {
        var selectedLanguage = $(this).text();
        $('#languageDropdown').text(selectedLanguage);
    });


    $(".form-block").each(function() {
        setupFormBlock($(this));
    });

    var categoryItems = $(".category-filter .category-item");
    categoryItems.addClass("parent");
    categoryItems.first().removeClass("parent").addClass("opened").find('.subcategory').removeClass("d-none");

    $(".category-filter .category-item a").click(function(e) {
        e.preventDefault();
        var subcategory = $(this).next(".subcategory");

        if (subcategory.is(":visible")) {
            $(this).closest(".category-filter .category-item").removeClass("opened").addClass("parent");
            subcategory.slideUp(300, function() {
                subcategory.addClass("d-none");
            });
        } else {
            $(this).closest(".category-filter .category-item").removeClass("parent").addClass("opened");
            subcategory.removeClass("d-none").css("display", "none");
            subcategory.slideDown(300, function() {
            });
        }
    });

    $(".category .category-item > a").click(function(e) {
        e.preventDefault();
        var subcategory = $(this).next(".subcategory");

        if (subcategory.is(":visible")) {
            $(this).closest(".category .category-item").removeClass("opened").addClass("parent");
            subcategory.slideUp(300, function() {
                subcategory.addClass("d-none");
            });
        } else {
            $(this).closest(".category .category-item").removeClass("parent").addClass("opened");
            subcategory.removeClass("d-none").css("display", "none");
            subcategory.slideDown(300, function() {
            });
        }
    });

    $('.request-application form').submit(function(e) {
        e.preventDefault()

        let formElement = $('.request-application form').serialize();

        $.ajax({
            url: "/request-application",
            method: "POST",
            data: formElement,
            dataType: "json",
            success: function(response) {
                if (response.success) {
                    $('.request-application form').remove();
                    $('.request-application .success').removeClass('d-none');
                }

            },
            error: function(error) {
                console.log(error);
            }
        });

    })

    $('.try-again .item__body__btn-order-box .btn.btn-card').click(function(e) {
        e.preventDefault()

        $('#sendOrderForm').submit(function (e) {
            e.preventDefault()

            let number_of_bottles = $('[name=number_of_bottles]');
            let try_again_qty = $('.try-again .inputField').val();

            number_of_bottles.val(try_again_qty)

            let formElement = $('#sendOrderModal form').serialize();

            $.ajax({
                url: "/request-try-again",
                method: "POST",
                data: formElement,
                dataType: "json",
                success: function(response) {
                    console.log(response)
                    if (response.success) {
                        $('#sendOrderModal').modal('hide');
                        $('.try-again .order-box').remove();
                        $('.try-again .success').removeClass('d-none');
                    }

                },
                error: function(error) {
                    console.log(error);
                }
            });

        })


    })

    $('.water-view .order-box .btn.btn-card').click(function(e) {
        e.preventDefault()

        $('#sendOrderForm').submit(function (e) {
            e.preventDefault()

            let number_of_bottles = $('[name=number_of_bottles]');
            let try_again_qty = $('.water-view .inputField').val();

            number_of_bottles.val(try_again_qty)

            let formElement = $('#sendOrderModal form').serialize();

            $.ajax({
                url: "/request-try-again",
                method: "POST",
                data: formElement,
                dataType: "json",
                success: function(response) {
                    console.log(response)
                    if (response.success) {
                        $('#sendOrderModal').modal('hide');
                        $('.water-view .order-box').remove();
                        $('.water-view .success').removeClass('d-none');
                    }

                },
                error: function(error) {
                    console.log(error);
                }
            });

        })


    })

    $('.services .btn.btn-card').click(function(e) {
        e.preventDefault()

        $('#sendServicesModal #sendServicesForm [name="title"]').val($(this).data('title'));

        $('#sendServicesForm').submit(function (e) {
            e.preventDefault()

            let formElement = $('#sendServicesForm').serialize();

            $.ajax({
                url: "/request-services",
                method: "POST",
                data: formElement,
                dataType: "json",
                success: function(response) {
                    if (response.success) {
                        $('#sendServicesModal').modal('hide');
                        // $('.services .order-box').remove();
                        // $('.services .success').removeClass('d-none');
                    }

                },
                error: function(error) {
                    console.log(error);
                }
            });

        })


    })

    window.getCartItemQty();

    let phoneInput = $("input[name=phone]");
    let phoneMask = new Inputmask("+38 (099) 999-99-99");
    phoneMask.mask(phoneInput);

});




let setupFormBlock = function($formBlock) {
    const $inputField = $formBlock.find(".inputField");
    const $decreaseButton = $formBlock.find(".decreaseButton");
    const $increaseButton = $formBlock.find(".increaseButton");

    $decreaseButton.on("click", function() {
        if ($inputField.val() > parseInt($inputField.attr("min"))) {
            $inputField.val(parseInt($inputField.val()) - 1);
            if (typeof $inputField.data("product-id") !== 'undefined') {
                updateCartItemQty($inputField.data("product-id"), $inputField.val());
            }

        }
    });

    $increaseButton.on("click", function() {
        if ($inputField.val() < parseInt($inputField.attr("max"))) {
            $inputField.val(parseInt($inputField.val()) + 1);
            if (typeof $inputField.data("product-id") !== 'undefined') {
                updateCartItemQty($inputField.data("product-id"), $inputField.val());
            }

        }
    });

    $inputField.on("input", function() {
        let inputValue = $inputField.val().replace(/\D/g, "");
        const maxInputValue = parseInt($inputField.attr("max"));

        if (inputValue > maxInputValue) {
            inputValue = maxInputValue;
        }

        $inputField.val(inputValue);
        if (typeof $inputField.data("product-id") !== 'undefined') {
            updateCartItemQty($inputField.data("product-id"), $inputField.val());
        }

    });
}


let updateCartItemQty = function(productId, newQuantity) {
    $.ajax({
        url: "/ua/shopping-card/update-cart",
        method: "POST",
        data: {
            product_id: productId,
            quantity: newQuantity
        },
        dataType: "json",
        success: function(response) {
            if (response.success) {
                var productElement = $(".totalProductSum[data-product-id='" + productId + "']");
                productElement.text(response.total_product_sum);

                $("#totalSum").text(response.total_sum);
            }

            getCartItemQty();
        },
        error: function(error) {
            console.log(error);
        }
    });
}

window.getCartItemQty = function () {
    $.ajax({
        url: "/ua/get-basket-qty",
        method: "GET",
        dataType: "json",
        success: function(response) {
            if (response.success) {
                $("#totalQty").text(response.total_qty);
            }
        },
        error: function(error) {
            console.log(error);
        }
    });
}

window.sendGetRequest = function(url) {
    $.get(url, function(response) {
        window.getCartItemQty();
    }).fail(function(error) {
        console.log(error);
    });
};